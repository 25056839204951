<template>
  <div id="Orders">
    <v-container class="lighten-5">
      <v-container>
        <dashboard-core-app-bar />
      </v-container>
      <v-row style="margin-top: 20px">
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
        <v-col cols="12" md="12">
          <v-card class="pa-3" outlined tile>
            <v-card-title>Mise à jour des status </v-card-title>
            <v-card-text style="margin-top: 20px">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-select
                    item-value="value"
                    v-model="btnStatut"
                    item-text="name"
                    :items="stateListe"
                    label="Sélectionner le statut"
                    outlined
                    @input="onStateChange"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-select
                    v-model="listStateOrder"
                    item-value="value"
                    item-text="name"
                    :items="changeState"
                    label="Sélectionner l'état"
                    :disabled="!btnStatut"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" align="right">
                  <v-btn
                    color="primary"
                    :disabled="disableButtonState"
                    :loading="loading"
                    @click="dialogConfirmChangeState = true"
                    >Valider</v-btn
                  ></v-col
                >
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="pa-3" outlined tile>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4" sm="12">
                  <v-text-field
                    label="Order ID"
                    placeholder="Recherche par Order ID"
                    v-model="orderSearch"
                    @input="searcheByOrderId"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="12">
                  <v-select
                    item-value="value"
                    item-text="name"
                    :items="filterListe"
                    label="Sélectionner le filtre"
                    outlined
                    v-model="selectFilter"
                    @input="onFilterChange(selectFilter)"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" sm="12">
                  <v-select
                    :disabled="!selectFilter"
                    :items="filter"
                    label="Sélectionner l'état"
                    item-value="value"
                    item-text="name"
                    v-model="stateOrder"
                    outlined
                    @input="onStateFilterChange"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card>
              <v-card-title> Liste Des Commandes </v-card-title>

              <v-data-table
                :footer-props="{
                  'items-per-page-options': [5, 10, 15, 30],
                }"
                v-model="selected"
                :headers="headers"
                :items="orders"
                :search="search"
                :server-items-length="totalOrdersCount"
                :options.sync="options"
                :loading="loading"
                item-key="order_id"
                show-select
                class="elevation-1"
              >
                <template v-slot:item.order_id="{ item }">
                  <v-btn @click="redirectToOrderDetails(item)" text
                    >{{ item.order_id }}
                  </v-btn>
                </template>
                <template v-slot:item.nameBuy="{ item }">
                  <v-btn
                    @click="redirectToUserDetails(item)"
                    text
                    style="text-transform: unset !important"
                  >
                    {{ item.nameBuy.lastName }}
                    {{ item.nameBuy.firstName }}
                  </v-btn>
                </template>

                <template v-slot:item.priceHt="{ item }">
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-on="on" v-bind="attrs">
                        {{ item.priceHt }} DT
                      </span>
                    </template>
                    <span style="text-align: center">
                      Prix TTC : <br />
                      {{ item.priceTtc }} DT
                    </span>
                  </v-tooltip>
                </template>

                <template v-slot:item.orderState="{ item }">
                  <v-chip :style="item.orderState.backgroundColor">
                    <span :style="item.orderState.color">
                      {{ item.orderState.text }}
                    </span>
                  </v-chip>
                </template>
                <template v-slot:item.deliveryState="{ item }">
                  <v-chip :style="item.deliveryState.backgroundColor">
                    <span :style="item.deliveryState.color">
                      {{ item.deliveryState.text }}
                    </span>
                  </v-chip>
                </template>
                <template v-slot:item.paymentMode="{ item }">
                  <v-chip :style="item.paymentMode.backgroundColor">
                    <span :style="item.paymentMode.color">
                      {{ item.paymentMode.text }}
                    </span>
                  </v-chip>
                </template>
                <template v-slot:item.paymentState="{ item }">
                  <v-chip :style="item.paymentState.backgroundColor">
                    <span :style="item.paymentState.color">
                      {{ item.paymentState.text }}
                    </span>
                  </v-chip>
                </template>
                <template v-slot:item.downloadFacture="{ item }">
                  <v-btn
                    :href="item.facture"
                    v-if="item.facture != null"
                    icon
                    style="background-color: white; color: gray"
                  >
                    <v-icon size="20px">mdi-download</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialogConfirmChangeState" persistent max-width="490">
          <v-card>
            <v-card-text
              >Êtes-vous sûr de vouloir enregistrer les changements
              ?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="closeDialogStatus()">
                Annuler
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                @click="onChangeStatus()"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import db from "../../../firebaseInit";
import NodesName from "../../utils/nodesName";
import nodesFieldName from "../../utils/nodesFieldName";
import Constants from "./../../utils/constants";
import FirebaseDB from "../../utils/firebaseDB";
import moment from "moment";
import FunctionsUtils from "../../utils/functions";
import "vue-hotel-datepicker2/dist/vueHotelDatepicker2.css";
import constants from "./../../utils/constants";
import firebase from "firebase";

export default {
  name: "PostedProducts",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
  },
  data() {
    return {
      OrderStateFilter: [
        constants.CREE,
        constants.EN_COURS_DE_PREPARATION,
        constants.EN_ATTENTE_DE_LIVRAISON,
        constants.EN_COURS_DE_LIVRAISON,
        constants.RETOURNEE,
        constants.LIVREE,
        constants.ANNULEE,
      ],
      DeliveryStateFilter: [
        constants.VALIDEE,
        constants.LIVRAISON_EN_COURS,
        constants.TERMINEE,
        constants.ANNULEE,
      ],
      PaymentModeFilter: [constants.CASH, constants.CB],
      PaymentStateFilter: [
        constants.CREE,
        constants.VALIDE,
        Constants.ANNULE,
        constants.FINALISE,
        constants.REMBOURSE,
      ],
      filterListe: [
        {
          name: constants.ORDER_STATE_LIBELLE,
          value: constants.ORDER_STATE,
        },
        {
          name: constants.DELIVERY_STATE_LIBELLE,
          value: constants.DELIVERY_STATE,
        },
        {
          name: constants.PAYMENT_MODE_LIBELLE,
          value: constants.PAYMENT_MODE,
        },
        {
          name: constants.PAYMENT_STATE_LIBELLE,
          value: constants.PAYMENT_STATE,
        },
      ],
      stateListe: [
        {
          name: constants.ORDER_STATE_LIBELLE,
          value: constants.ORDER_STATE,
        },
        {
          name: constants.DELIVERY_STATE_LIBELLE,
          value: constants.DELIVERY_STATE,
        },

        {
          name: constants.PAYMENT_STATE_LIBELLE,
          value: constants.PAYMENT_STATE,
        },
      ],
      filter: [],
      stateOrder: null,
      selectFilter: null,
      clientName: [],
      orders: [],
      totalOrders: [],
      search: "",
      options: {},
      totalOrdersCount: 0,
      loading: true,
      lastVisibleOrder: null,
      userId: null,
      facture: null,
      nameBuy: [],
      selected: [],
      orderSearch: null,
      loading: false,
      listStateOrder: null,
      changeState: [],
      dialogConfirmChangeState: false,
      btnStatut: null,
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],
    };
  },
  async beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.selectFilter = "orderState";
      vm.filter = vm.OrderStateFilter;
      if (to.params.stateOrder) {
        vm.stateOrder = to.params.stateOrder;
      }
    });
  },

  methods: {
    onFilterChange: function (event) {
      switch (event) {
        case "orderState":
          this.filter = this.OrderStateFilter;
          break;
        case "deliveryState":
          this.filter = this.DeliveryStateFilter;
          break;
        case "paymentMode":
          this.filter = this.PaymentModeFilter;
          break;
        case "paymentState":
          this.filter = this.PaymentStateFilter;
          break;
      }
    },
    onStateChange: function (event) {
      switch (event) {
        case "orderState":
          this.changeState = this.OrderStateFilter;
          break;
        case "deliveryState":
          this.changeState = this.DeliveryStateFilter;
          break;
        case "paymentState":
          this.changeState = this.PaymentStateFilter;
          break;
      }
    },
    /*Fonction qui verifie quel status a mettre à jour (status commande, livraison, payment)*/
    onChangeStatus() {
      switch (this.btnStatut) {
        case "orderState":
          this.updateStateOrder();
          break;
        case "deliveryState":
          this.updateDeliveryState();
          break;
        case "paymentState":
          this.updatePaymentState();
          break;
      }
    },

    onStateFilterChange: function (event) {
      this.totalOrders = [];
      this.totalOrdersCount = null;
      this.orders = [];
      this.lastVisibleOrder = null;
      this.loadOrders();
    },
    searcheByOrderId: function () {
      if (this.orderSearch.length > 2 || this.orderSearch.length < 1) {
        this.totalOrders = [];
        this.orders = [];
        this.lastVisibleOrder = null;
        this.loadOrders();
      }
    },
    redirectToOrderDetails(item) {
      this.$router.push({
        name: "OrderDetail",
        params: {
          doc_id: item.doc_id,
        },
      });
    },
    redirectToUserDetails(user) {
      this.$router.push({
        name: "UserDetail",
        params: {
          userId: user.userId,
        },
      });
    },
    async loadOrders() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var query = db.collection(NodesName.USER_ORDER);
      if (
        !FunctionsUtils.isNullOrEmptyOrSpaces(this.selectFilter) &&
        !FunctionsUtils.isNullOrEmptyOrSpaces(this.stateOrder)
      ) {
        query = query.where(String(this.selectFilter), "==", this.stateOrder);
      }
      /* Search by order ID */
      if (!FunctionsUtils.isNullOrEmptyOrSpaces(this.orderSearch)) {
        query = query.where(
          nodesFieldName.ORDER_IDENTIFIER,
          "==",
          this.orderSearch
        );
      }

      var generalStats = await FirebaseDB.getGeneralStats();
      generalStats.forEach((stat) => {
        this.totalOrdersCount = stat.data().number_total_orders;
      });

      if (this.totalOrders.length < this.totalOrdersCount) {
        var querySnapshot = await FirebaseDB.getDocumentsWithPagination(
          query,
          this.lastVisibleOrder,
          itemsPerPage,
          this.totalOrdersCount
        );
        let i = 0;
        querySnapshot.forEach((order) => {
          if (Object.entries(order.data()).length !== 0) {
            const found = this.totalOrders.some(
              (el) => el.order_id === order.id
            );

            if (!found) {
              this.totalOrders.push({
                doc_id: order.id,
                order_id: order.data().orderIdentifier,
                client: null,
                date: moment(
                  new Date(order.data().creationDate.seconds * 1000)
                ).format("L HH:mm:ss"),
                price: order.data().productOrderList[0],
                state: order.data().state,
                orderList: order.data().productOrderList[0],
                orderState: FunctionsUtils.getOrderState(
                  order.data().orderState
                ),
                userId: order.data().userId,
                deliveryState: FunctionsUtils.getDeliveryState(
                  order.data().deliveryState
                ),
                paymentState: FunctionsUtils.getPaymentState(
                  order.data().paymentState
                ),
                paymentMode: FunctionsUtils.getPaymentMode(
                  order.data().paymentMode
                ),
                facture: order.data().invoice,
                nameBuy: order.data().deliveryAddress,
                priceTtc: order.data().price_TTC,
                priceHt: order.data().price_HT,
                detail: "",
                downloadFacture: "",
              });
              i++;
              if (querySnapshot.size == i) {
                this.lastVisibleOrder = order;
              }
            }
          }
        });
      }

      this.orders = FunctionsUtils.customSortDataTable(
        this.totalOrders,
        sortBy,
        sortDesc
      );
      this.orders = FunctionsUtils.getItemsPerPageForDataTable(
        this.totalOrders,
        itemsPerPage,
        page,
        this.totalOrdersCount
      );

      this.loading = false;
    },

    updateStateOrder() {
      this.loading = true;
      for (var i = 0; i < this.selected.length; i++) {
        db.collection(NodesName.USER_ORDER)
          .doc(this.selected[i].doc_id)
          .update({
            admin_history_orderState: firebase.firestore.FieldValue.arrayUnion({
              updatedBy: firebase.auth().currentUser.uid,
              updatedDate: new Date(),
              orderStateFrom: this.selected[i].orderState.text,
              orderStateTo: this.listStateOrder,
            }),
            orderState: this.listStateOrder,
          })
          .then(() => {
            this.loading = false;
            this.dialogConfirmChangeState = false;
            this.listStateOrder = null;
            this.btnStatut = null;
          });
        // permet d'afficher le nouveau statut de la commande directement apres enregistrement des modifications
        this.selected[i].orderState = FunctionsUtils.getOrderState(
          this.listStateOrder
        );
      }
    },
    updatePaymentState() {
      this.loading = true;
      for (var i = 0; i < this.selected.length; i++) {
        db.collection(NodesName.USER_ORDER)
          .doc(this.selected[i].doc_id)
          .update({
            admin_history_paymentState:
              firebase.firestore.FieldValue.arrayUnion({
                updatedBy: firebase.auth().currentUser.uid,
                updatedDate: new Date(),
                paymentStateFrom: this.selected[i].paymentState.text,
                paymentStateTo: this.listStateOrder,
              }),
            paymentState: this.listStateOrder,
          })
          .then(() => {
            this.loading = false;
            this.dialogConfirmChangeState = false;
            this.listStateOrder = null;
            this.btnStatut = null;
          });
        // permet d'afficher le nouveau statut de paiement directement apres enregistrement des modification
        this.selected[i].paymentState = FunctionsUtils.getPaymentState(
          this.listStateOrder
        );
      }
    },
    updateDeliveryState() {
      this.loading = true;
      for (var i = 0; i < this.selected.length; i++) {
        db.collection(NodesName.USER_ORDER)
          .doc(this.selected[i].doc_id)
          .update({
            admin_history_deliveryState:
              firebase.firestore.FieldValue.arrayUnion({
                updatedBy: firebase.auth().currentUser.uid,
                updatedDate: new Date(),
                deliveryStateFrom: this.selected[i].deliveryState.text,
                deliveryStateTo: this.listStateOrder,
              }),
            deliveryState: this.listStateOrder,
          })
          .then(() => {
            /*this.orders = [];
            this.totalOrders = [];
            this.lastVisibleOrder = null;
            this.loadOrders();*/
            this.loading = false;
            this.dialogConfirmChangeState = false;
            //this.selected = [];
            this.listStateOrder = null;
            this.btnStatut = null;
          });
        // permet d'afficher le nouveau statut de livraison directement apres enregistrement des modification
        this.selected[i].deliveryState = FunctionsUtils.getDeliveryState(
          this.listStateOrder
        );
      }
    },
    closeDialogStatus() {
      this.dialogConfirmChangeState = false;
      this.selected = [];
      this.listStateOrder = null;
      this.btnStatut = null;
    },
  },

  created() {},
  computed: {
    headers() {
      return [
        { text: "Order ID", value: "order_id", align: "center" },
        { text: "Client", value: "nameBuy", align: "center" },
        { text: "Prix HT", value: "priceHt", align: "center" },
        { text: "État de la Commande", value: "orderState", align: "center" },
        { text: "État de livraison", value: "deliveryState", align: "center" },
        { text: "Mode de Paiement", value: "paymentMode", align: "center" },
        { text: "État du paiement", value: "paymentState", align: "center" },
        { text: "Date et heure", value: "date", align: "center" },
        { text: "Facture", value: "downloadFacture" },
      ];
    },

    //Fonction qui permet d'activer ou désactiver le bouton "valider"
    disableButtonState() {
      if (this.selected.length > 0 && this.listStateOrder) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.loadOrders();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(212, 212, 212, 0.986);
}
</style>
